//定义字体
.index_main{
  display: block;
}
* {
  -webkit-touch-callout: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-family:"Microsoft YaHei", PingFangSC-Regular, PingFang SC,sans-serif;
}
a{
  text-decoration: none;
  font-size: 14px;
  color:#333;
  &:hover{
    text-decoration: none;
    color: #009688;
  }
}

a:focus{
  outline:none;
  color:#333;
}
a:hover{
  text-decoration: none;
}
body,
html,
.app {
  padding: 0;
  margin: 0;
  background: #fff;
}
img{
  max-width: 100%;
}
a,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
span,
p,
em,
img,
input,
button,
div {
  font-size: 14px;
  color:#333;
  font-style: normal;
  list-style: none;
  transition: all .2s linear;
  -moz-transition: all .2s linear;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  margin:0;
  padding:0;
  &:focus{
    outline: none;
  }
}
.noPad{
  padding:0 !important;
}
p{
  line-height: 1.5;
}
div{
  font-size: 0;
}

/* Safari，Chrome WebKit browsers */
textarea::-webkit-input-placeholder,input::-webkit-input-placeholder {
  color: #999;
}
/* 火狐 Mozilla Firefox 4 to 18 */
textarea::-webkit-input-placeholder,input:-moz-placeholder {
  color: #999;
}
/* 火狐 Mozilla Firefox 19+ */
textarea::-webkit-input-placeholder,input::-moz-placeholder {
  color: #999;
}
/* Internet Explorer 10+ */
textarea::-webkit-input-placeholder,input:-ms-input-placeholder {
  color: #999;
}

.color-pink {
  color: #eb2f96;
}

.color-red {
  color: #f5222d;
}

.color-orange {
  color: #fa8c16;
}

.color-green {
  color: #52c41a;
}

.color-cyan {
  color: #13c2c2 !important;
}

.color-blue {
  color: #1890ff !important;
}

.color-purple {
  color: #722ed1 !important;
}
select,
textarea,
input{
  outline: none;
}
//pc
.pc_headerMain{
  width: 100%;
  background: #E6F7F5;
  height:70px;
  padding:0 15px;
  .pc_headerBox{
    max-width: 1200px;
    margin:0 auto;
    display: flex;
    height: 70px;
    justify-content: space-between;
    align-items: center;
    .leftDiv{
      max-width: 181px;
    }
    .rightDiv{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .search{
        width: 20%;
        height: 32px;
        background: #009688;
        border-radius: 4px;
        position: relative;
        overflow: hidden;
        input{
          background: transparent;
          width:100% !important;
          padding:0 32px 0 8px;
          border-color: transparent;
          height: 100%;
          color: #fff;
        }
        input::-webkit-input-placeholder {
          color:rgba(255,255,255,.5);
        }
        img{
          position: absolute;
          right:8px;
          top:8px;
          cursor: pointer;
        }
         .history-data{
           display: none;
           position: absolute;
           top: 32px;
           width: 100%;
           z-index: 111;
           background: #fafafa;
           border-top: 1px solid #f2f2f2;
           line-height: normal;
           text-align: left;
           padding: 10px ;
           .search-history-wrapper{
             font-size: 0.75rem;
             margin-top: 10px;
             box-sizing: border-box;
             height: 80px;
             overflow-y: auto;
             overflow-x: hidden;
             padding-right: 10px;
           }
         }
      }
      .searchActive{
        width: 30%;
      }
      .rItem{
        display: flex;
        align-items: center;
        margin-left:20px;
        width: 30px;
        overflow: hidden;
        img{
          width: 30px;
        }
        a{
          margin-left:10px;
          opacity: 0;
          white-space:nowrap;
        }
        &:hover{
          a{
            opacity:1;
            color: #07A696;
          }
        }
      }
      .yuyan{
        margin-left:20px;
        width: 100px;
        height: 32px;
        background: #009688;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover{
          background: #07A696;
        }
        img{
          width:16px;
          margin-right:4px;
        }
        p{
          color:#fff;
          font-size: 14px;
        }
      }
    }
  }
}
.aOne{
  &:hover{
    text-decoration: underline;
  }
}
.pc_menuMain{
  background: #02796B;
  padding:0 15px;
  .pc_menuBox{
    max-width: 1200px;
    margin:0 auto;
    height:48px;
    ul{
      height:48px;
      display: flex;
      justify-content: space-between;
      li{
        position: relative;
        width: 14.28%;
        &:after{
          height: 30px;
          width: 1px;
          background: rgba(255,255,255,.1);
          content:"";
          position: absolute;
          top:9px;
        }
        &:last-child{
          &:before{
            height: 30px;
            width: 1px;
            background: rgba(255,255,255,.1);
            content:"";
            position: absolute;
            top:9px;
            right:0;
          }
        }
        a{
          color: #fff;
          height:48px;
          text-align: center;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover{
            text-decoration: none;
            background: rgba(255,255,255,.3);
          }
          img{
            margin-left:8px;

          }
        }
        .showDiv{
          position: absolute;
          top:48px;
          background: #026357;
          width: 410px;
          display: none;
          z-index: 10;
          a{
            display: flex;
            align-items: flex-start;
            justify-content: left;
            height: auto;
            padding:10px;
            i{
              font-size: 40px;
              margin-right:8px;
            }
            h2{
              display: flex;
              flex-wrap: wrap;
              font-weight: normal;
                span{
                  width: 100%;
                  color: #FFFFFF;
                  display: block;
                  text-align: left;
                  font-size: 16px;
                  margin-bottom:4px;
                  line-height: 1.8;
                  font-weight: normal;
                  &:last-child{
                    font-size: 12px;
                    font-weight: normal;
                    line-height: 1.5;
                  }
              }
            }
          }
          a:hover{
            color: #fff;
            text-align: left;
            background: none;
            background: #009582;
            h2{
              span{
                color: #fff;
              }
            }
          }
        }
        .jp_showDiv{
          width: 170%;
        }
        &:hover{
          a{
            img{
              transform: rotate(90deg);
            }
          }
          .showDiv{
            display: block;
          }
        }
      }
    }
  }
}
.app_headerMain,.app_menuMain{
  display: none;
}
.pc_headerMain_ac{
  position: fixed;
  top:0;
  z-index: 19;
  width: 100%;
  box-shadow: 0 5px 7px rgba(0,0,0,.2) !important;
}
.app_headerMain_ac{
  position: fixed;
  top:0;
  z-index: 19;
  width: 100%;
  box-shadow: 0 5px 7px rgba(0,0,0,.2) !important;
}
.pc_menuMain_ac{
  position: fixed;
  width: 100%;
  top: 70px;
  z-index: 19;
  box-shadow: 0 5px 7px rgba(0,0,0,.2) !important;
}
.pc_footerMain{
  background: #30343E;
  padding:0 15px;
  .pc_footerBox{
    max-width: 1200px;
    margin:0 auto;
    padding:50px 0;
    ul{
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 30px;
      border-bottom:2px solid #434753;
      li{
        width: 25%;
        padding-left:3%;
        a{
          font-size: 18px;
          color:#fff;
          margin-bottom:15px;
          display: block;
          &:hover{
            color:#fff;
          }
        }
        .bomList{
          a{
            color:#A2A6B2;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            display: block;
            margin:0;
            &:hover{
              color: #009688;
            }
            em{
              display: none;
            }
          }
        }
      }
    }
    .footerMenuList{
      padding:30px 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom:2px solid #434753;
      a{
        font-size: 18px;
        color:#fff;
        width: 25%;
        padding-left:3%;
        &:hover{
          color: #00A693;
        }
      }
    }
    .footerOuth{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:15px 0;
      margin-top:10px;
      a{
        color: #A2A6B2;
        display: flex;
        align-items: center;
        img{
          margin-right:5px;
        }
        &:hover{
          color:#fff;
        }
        margin:0 10px;
      }
      p{
        color: #A2A6B2;
        margin:0 ;
      }
    }
    .footerImgList{
      display: flex;
      justify-content: center;
      align-items: center;
      padding:15px 0;
      margin-top:10px;
      a{
        margin:0 18px;
      }
    }
    p.footerText{
      color: #A2A6B2;
      text-align: center;
    }
  }
}

.tabTitleMain_ac,.tabTitleMain_ac2{
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  position: fixed !important;
  background: #009688 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 99999 !important;
  margin: 0 !important;
  height: 48px !important;
  padding: 0 10px !important;
  box-shadow: 0 5px 7px rgba(0,0,0,.2) !important;
  max-width:100% !important;
  .tabTitleBox{
    position: initial !important;
    top:10px !important;
    display: flex !important;
    max-width: 1200px !important;
    margin: 0 auto !important;
    justify-content: space-between !important;
    padding: 0 !important;
    height: 48px !important;
    transition: none;
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: none;
    a:after{
      top:0 !important;
    }
  }
}
.tabTitleMain_ac2{
  padding: 0  !important;
  .tabTitleBox{
    width: 100% !important;
  }
}
.navTitle{
  width: 100%;
  background: #0CA18F;
  .navTitleBox{
    line-height: 1.5;
    font-size: 12px;
    color:#fff;
    max-width: 1200px;
    margin:0 auto;
    padding:8px 0;
    a{
      color:#fff;
      font-size: 12px;
      display: inline-block;
      margin:0 10px ;
      &:hover{
        text-decoration: underline;
      }
      &:first-child{
        margin-left: 0;
      }
    }
  }
}
.bottomBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding:20px 0;
  background: #FFFFFF;
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
  a{
    background: #fff;
    box-shadow: 0 -2px 20px 0px rgba(0, 0, 0, 0.05);
    border-radius: 2px;
    color: #009688;
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 10px;
    border:1px solid #009688;
    border-radius: 2px;
    font-size: 18px;
    &.active{
      background: #009688;
      box-shadow: 0 -2px 20px 0px rgba(0, 0, 0, 0.05);
      border-radius: 2px;
      color: #fff;
      &:hover{
        background: rgba(0,150,136,.8);
      }
    }
    &:hover{
      background: rgba(0,150,136,.1);
    }
  }
}

.mailBanner{
  height:400px;
  width: 100%;
  background:  linear-gradient(87deg, #0FC9B7 0%, #009688 100%);
  .bannerBox{
    width: 100%;
    position: relative;
    height: 400px;
    background: url("../cn/cnImg/z_mailBannerBg.png") no-repeat center center;
    .bannerMain{
      position: relative;
      max-width: 1200px;
      margin:0 auto;
      height: 100%;
      .text{
        position: absolute;
        top:80px;
        z-index: 9;
        left:0;
        h1{
          color: #fff;
          font-size: 44px;
          margin-bottom: 10px;
          font-weight: normal;
          text-align: left;
        }
        ul{
          li{
            list-style: disc;
            font-size: 20px;
            color: #fff;
            text-align: left;
            margin-left: 20px;
            line-height: 1.5;
          }
        }
      }
      .text_jp{
        ul{
          li{
            font-size: 16px;
          }
        }
      }
      .text_jp_xn{
        top:60px;
        ul{
          li{
            font-size: 14px;
          }
        }
      }
      .domainText{
        top:60px;
      }
      img.rightImg{
        position: absolute;
        right: 0;
        height: auto;
        z-index: 1;
        top: 0;
        max-height: 100%;
      }
      img.rightImg_jp{
        max-height: 80%;
        top:0;
      }
      .dataSafetyTop{
        top:40px;
        p{
          font-size: 20px;
          color:#fff;
          max-width: 55%;
          padding-right: 20px;
        }
      }
      .dataSafetyTopJp{
        max-width: 50%;
        top:60px;
        h1{
          font-size: 34px;
        }
        p{
          max-width: 100%;
        }
      }
      .dataSafetyBanner{
        position: relative;
        width: 100%;
        height: 100%;
        .imgBox{
          width: 40%;
          position: absolute;
          right: 5%;
          background: #fff;
          padding:10px;
          border-radius: 5px;
          top: 50%;
          transform: translateY(-58%);
          z-index: 9;
          img{
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.app_mailBanner{
  display: none;
}

.toTopBox{
  position: fixed;
  right:15px;
  top:80%;
  margin-top:-50px;
  display: flex;
  flex-wrap: wrap;
  width: 42px;
  z-index: 10;
  .toTopList{
    width: 100%;
    height:42px;
    margin-bottom:15px;
    font-size: inherit;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #009688;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
    border-radius: 42px;
    cursor: pointer;
    &:hover{
      background: #17c9b7;
    }
    &:last-child{
      background: #fff;
      &:hover{
        background: #ebfff9;
      }
    }
    img{
      cursor: pointer;
    }
    a{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .toTopCon{
      display: none;
      width: 255px;
      padding:15px;
      background: #fff;
      position: absolute;
      right:65px;
      box-shadow: 1px -1px 38px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      z-index: 9;
      top: -38px;
      &:after{
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #fff;
        transform:rotate(45deg);
        -ms-transform:rotate(45deg); /* Internet Explorer */
        -moz-transform:rotate(45deg); /* Firefox */
        -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
        -o-transform:rotate(45deg); /* Opera */
        content: "";
        right: -10px;
        top: 50%;
        margin-top: -10px;
        z-index: 0;
      }
      span{
        font-size: 16px;
        color: #666;
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
      }
      p{
        font-size: 14px;
        color:#666;
      }
      img{
        width: 30px;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0.6;
      }
    }
  }
}
.blockBg{
  background: rgba(0,0,0,.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999991 !important;
  top: 0;
  left: 0;
  display: none;
}

.popMain,.domainPop{
  display: none;
  position: fixed;
  top:10vh;
  width:880px;
  left:50%;
  margin-left:-440px;
  max-height:80vh;
  background: #fff;
  z-index: 999992;
  overflow: hidden;
  border-radius: 2px;
  .popMain_T{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding:20px;
    position: relative;
    .close{
      position: absolute;
      right:20px;
      top:15px;
      width: 27px;
      height: 27px;
      cursor: pointer;
      z-index: 11;
      img{
        max-width: 100%;
      }
    }
    h1{
      text-align: center;
      font-size:20px;
      position: relative;
      display: initial;
      margin-bottom: 5px;
      width: 100%;
    }
    p{
      font-size: 14px;
      width: 100%;
      em{
        color:#ff0000;
      }
    }
  }
  .popCon{
    margin:0 auto;
    max-height:calc(80vh - 92px);
    overflow-y: auto;
    width: 100%;
    padding: 0 4%;
    border-bottom:1px solid #e8e8e8;
    p{
      margin-bottom:15px;
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }
    .tabBox{
      border-bottom:1px solid #e8e8e8;
      margin-bottom: 24px;
      padding-bottom: 5px;
      ul{
        li{
          display: flex;
          padding:0 0 15px 0;
          font-size: 16px;
          align-items: center;
          em{
            color: #FF0000;
            font-size:14px;
            font-weight: bold;
            width: 20px;
            text-align: center;
          }
          .iptBox,.domainIpnt{
            flex:1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            select{
              border-radius: 4px;
              width:49%;
              min-width: auto;
              margin-right:10px;
              padding:5px 10px;
              border:1px solid #e8e8e8;
              font-size: 14px;
              &:last-child{
                margin:0;
              }
            }
            textarea,input{
              padding:5px 10px;
              border:1px solid #e8e8e8;
              border-radius: 4px;
              font-size: 14px;
              width:100%;
            }
          }
          .domainIpnt{
            input{
              border-radius:4px 0 0 4px;
              border-right: none ;
              height: 31px;
            }
            select{
              border-radius:0 4px 4px 0;
              height: 31px;
              width: 150px;
            }
          }
          span{
            border:1px solid #ccc;
            color:#333;
            height:20px;
            width: 20px;
            justify-content: center;
            display: flex;
            align-items: center;
            cursor: pointer;
          }
        }
      }
      button{
        border-radius: 4px;
        border: 1px solid #009688;
        background: #fff;
        color:#009688;
        font-size: 14px;
        padding: 5px 10px;
        margin: 10px auto;
        display: block;
        cursor: pointer;
        &:hover{
          background: #e9fffb;
        }
      }
    }
    .radioBox{
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px 15px 5px 15px;
      margin-bottom: 24px;
      label{
        width: 33.33%;
        color:#000;
        font-size: 14px;
        margin-bottom:15px;
        display: flex;
        align-items: center;
        input[type="radio"]{
          width:16px;
          height:16px;
          border:1px solid #e8e8e8;
          margin-right:5px;
          position: relative;
        }
        input[type=radio]::after {
          position: absolute;
          top: 3px;
          left:3px;
          background-color: #fff;
          color: #fff;
          width: 10px;
          height: 10px;
          display: inline-block;
          visibility: visible;
          padding-left: 0;
          text-align: center;
          content: ' ';
          box-sizing: border-box;
          border-radius: 10px;
        }
        input[type=radio]:checked::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          border:1px solid #009a74;
          border-radius: 16px;
          z-index: 1;
        }
        input[type=radio]:checked::after {
          content: "";
          background-color: #009a74;
          border-color: #009a74;
        }
      }
    }
    .isYmName{
      display: none;
    }
    .ymRadioBox{
      justify-content: center;
      margin:0;
      label{
        width: 15%;
        justify-content: center;
      }
    }
    .yzmBox{
      display: flex;
      padding: 0 ;
      font-size: 1rem;
      align-items: center;
      justify-content: space-between;
      input{
        padding:5px 10px;
        border:1px solid #e8e8e8;
        border-radius: 4px;
        font-size: 14px;
        flex: 1;
      }
      em{
        color: #FF0000;
        font-size:14px;
        font-weight: bold;
        width: 20px;
        text-align: center;
      }
      span{
        width: 100px;
        height: 31px;
        border:1px solid #e8e8e8;
        img{
          width: 98px;
          height: 29px;
        }
      }
    }
    .textB  {
      width:100%;
        label{
          display: flex;
          margin: 25px auto 0 auto;
          align-items: center;
          input[type="checkbox"]{
            width:15px;
            height:15px;
            border:1px solid #d1d1d1;
            margin-right:5px;
            position: relative;
          }
          input[type=checkbox]::after {
            position: absolute;
            top: 0;
            background-color: #fff;
            color: #fff;
            width: 14px;
            height: 14px;
            display: inline-block;
            visibility: visible;
            padding-left: 0;
            text-align: center;
            content: ' ';
            border-radius: 2px;
            box-sizing: border-box;
            border: 1px solid #ddd;
          }

          input[type=checkbox]:checked::after {
            content: "";
            background-color: #009a74;
            border-color: #009a74;
          }

          input[type=checkbox]:checked::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 5px;
            width: 3px;
            height: 8px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            z-index: 1;
          }
          p{
            flex: 1;
            font-weight: normal;
            line-height: 1.5;
            font-size: 12px;
            text-align: left;
            a{
              color: #009688;
            }
          }
      }
    }
    .bomBtn{
      padding:20px 0 ;
      width:100%;
      margin-top:20px;
      text-align: center;
      justify-content: center;
      display: flex;
      border-top:1px solid #e8e8e8;
      button{
        width: 138px;
        height: 42px;
        background: #FFFFFF;
        border: 1px solid #E8E8E8;
        font-size: 20px;
        color: #666;
        cursor: pointer;
        border-radius: 5px;
        margin:0 10px;
        &:hover{
          background: #008363 ;
          color:#fff;
        }
        &:last-child{
          background: #008363 ;
          color: #fff;
          border:1px solid #008363 ;
          &:hover{
            background: #019b76;
          }
        }
        &:disabled{
          background: #555;
          color:#fff;
          border:1px solid #555;
          cursor: no-drop;
          &:hover{
            background: #555;
            color:#fff;
            border:1px solid #555;
            cursor: no-drop;
          }
        }
      }
    }
  }

}
.hintWan,.hintSuc{
  width: 300px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: fixed;
  top:0;
  left:50%;
  margin-left:-150px;
  display: flex;
  align-items: center;
  padding:10px 15px;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: -1;
  opacity: 0;
  p{
    color: #333;
    font-size: 14px;
    text-align: center;
    flex: 1;
  }
  img{
    margin-right:5px;
  }
}
.hintWan_ac,.hintSuc_ac{
  top:5%;
  z-index: 999993;
  opacity: 1;
}

.clearBf{
  &:after{
    display: none !important;
  }
  &:before{
    display: none !important;
  }
}
.domainSearchPop{
  width: 350px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: fixed;
  top:20%;
  left:50%;
  margin-left:-175px;
  z-index: 9999994;
  padding:15px 15px 55px 15px;
  display: none;
  button{
    background: #009688;
    color: #fff;
    border:none;
    padding:5px 8px;
    border-radius: 2px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
  }
  ul{
    li{
      display: flex;
      justify-content: flex-start;
      margin-top:10px;
      img{
        margin-right:10px;
        width: 18px;
      }
      &:first-child{
        margin:0;
      }
    }
  }
}
/* ----- footer-policy ----- */
.footer-policy {
  padding: 20px ;
  background-color:#eee;
  position: fixed;
  bottom:0;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0,.1);
}
.footer-policy .policy_box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  background-color: #EEEEEE;
  padding:20px;
  justify-content: center;
  max-width: 1200px;
  margin:0 auto;
  flex-wrap: wrap;
}
.footer-policy .policy_text {
  margin-right: 1em;
  color: #000;
  width: 100%;
}
.footer-policy .policy_action {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top:15px;
}
.footer-policy .policy_action > [class*="policy_"] {
  display: block;
  min-width: 115px;
  margin-right: 10px;
  padding: 5px;
  border: none;
  color: #fff;
  line-height: 1.5;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 65px;
  background: #02796B;
  border-radius: 4px;
}
.footer-policy .policy_action > [class*="policy_"]:hover {
  background: #009e8d;
}
.footer-policy .policy_action button span,.footer-policy .policy_action a span{
  color: #fff;
  font-size: 14px;
}
.footer-policy .policy_action{
  a.policy_detail {
    background: #fff;
    border:1px solid #02796B;
    span{
      color: #02796B;
    }
    &:hover{
      background: #e1fff8;
    }
  }
}
@media screen and (max-width: 1200px) {
  .mailBanner .bannerBox{
    .bannerMain {
      .text{
        left: 15px;
      }
      img.rightImg{
        right:20px;
      }
    }
  }
}
@media screen and (max-width: 1000px) {

}
@media screen and (max-width: 880px) {
  .popMain,.domainPop{
    width: 96%;
    margin:0;
    left:2%;
    .popCon {
      .radioBox{
        padding: 10px 0 5px 0;
        label{
          width: 50%;
        }
      }
      .tabBox ul li{
        padding:0 0 15px 0;
      }
      .yzmBox{
        padding:0 ;
      }
    }
  }
}

@media screen and (max-width: 830px){
  .footer-policy .policy_box{
    padding:0;
  }
  .popMain,.domainPop{
    top: 5vh;
    height: 90vh;
    .popMain_T{
      h1{
        font-size: 16px;
      }
    }
    .popCon{
      max-height:calc(90vh - 174px);
      p{
        font-size: 14px;
      }
      .tabBox ul li{
        .selectBox {
          justify-content: flex-start;
          flex-wrap: wrap;
          .selectCon{
            width: 100%;
            display: flex;
            margin:0 0 15px 0;
            &:last-child{
              margin:0;
            }
            select{
              width: 100%;
              margin:0;
            }
          }
        }
        .rf{
          input{
            width: 100%;
          }
        }
      }
    }
    .bomBtn{
      button{
        font-size: 16px;
      }
    }
  }
  .navTitle .navTitleBox{
    padding-left: 5px;
  }
  .pc_headerMain,.pc_menuMain{
    display: none;
  }
  .app_headerMain{
    height: 62px;
    background: #02796B;
    padding:0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftLogo{
      img{
        max-width: 150px;
      }
    }
    .rightIcon{
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg,img{
        margin-left:24px;
        width: 22px;
      }
    }
  }
  .app_menuMain{
    background: #02796B;
    position: fixed;
    width: 100%;
    display: none;
    z-index: 11;
    .menuC1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom:1px solid rgba(255,255,255,.49);
      flex-wrap: wrap;
      .menuC1Item{
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid rgba(255,255,255,.49);
        height: 44px;
        padding:0 15px;
        img{
          width: 30px;
          margin-right:8px;
        }
        a{
          color:#fff;
          flex: 1;
          &:hover{
            color: #fff;
            font-size: 14px;
          }
        }
      }
    }
    .menuC2{
      padding:12px 0;
      height: 64px;
      display: flex;
      justify-content: center;
      a{
        width: 94px;
        height:40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        background: #fff;
        color: #333;
        border-radius: 2px;
        margin:0 20px;
        &.active{
          background: #00A693;
          color: #fff;
        }
      }
    }
    ul{
      li{
        width: 100%;
        a{
          width: 100%;
          height:44px;
          display: flex;
          align-items: center;
          font-size: 14px;
          padding:0 15px;
          color: #fff;
          justify-content: space-between;
          border-top:1px solid rgba(255,255,255,.49);
        }
        a.toSvg{
          svg{
            &:first-child{
              display: none;
            }
          }
        }
        .showDiv{
          width: 100%;
          display: none;
          a{
            width: 100%;
            height:44px;
            display: flex;
            background: #00A693;
            align-items: center;
            font-size: 12px;
            padding:0 15px;
          }
        }
      }
    }
  }

  .pc_footerMain{
    .pc_footerBox{
      padding:10px 0;
      ul{
        flex-wrap: wrap;
        li{
          width: 100%;
          a{
            margin:20px 0 10px 0;
          }
          .bomList{
            display: flex;
            flex-wrap: wrap;
            a{
              width: 50%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              em{
                display: block;
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background:#A2A6B2;
                margin-right:4px;
              }
            }
          }
          .jp_bomList{
            a{
              height: auto;
              line-height: normal;
              align-items: inherit;
              padding-right: 10px;
              margin-bottom: 10px;
              &:nth-child(2),&:nth-child(4){
                padding:0;
              }
              em{
                margin-top:9px;
              }
            }
          }
        }
      }
      .footerOuth{
        flex-wrap: wrap;
        a,p{
          font-size: 12px;
          line-height: 1.5;
        }
      }
      p.footerText{
        font-size: 12px;
      }
      .footerMenuList{
        flex-wrap: wrap;
        padding: 20px 0 0 0;
        a{
          width: 50%;
          margin-bottom:20px;
          font-size: 16px;
        }
      }
    }
  }

  .mailBanner{
    display: none;
  }
  .app_mailBanner{
    display: block;
    width: 100%;
    padding:0 15px;
    background: linear-gradient(87deg, #0FC9B7 0%, #009688 100%);
    .app_bannerBox{
      background: url("../cn/cnImg/z_mailBannerBg.png") no-repeat center center;
      width: 100%;
      .topBox{
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:0 5px;
        h1{
          color:#fff;
          font-size: 24px;
        }
        .newRy{
          font-size: 18px;
        }
        img{
          height: 80%;
        }
        .dataSafetyBanner{
          position: relative;
          width: 100%;
          .imgBox{
            width: 80%;
            position: absolute;
            right: 0;
            background: #fff;
            padding: 5px;
            border-radius: 5px;
            z-index: 9;
            top: 50%;
            transform: translateY(-50%);
            img{
              width: 100%;
            }
          }
        }
      }
      .bomBox{
        border-top:1px solid rgba(255,255,255,.48);
        padding:10px 0 50px 0;
        line-height: 2;
        color: #fff;
        ul{
          li{
            list-style: disc;
            font-size: 12px;
            color: #fff;
            text-align: left;
            margin-left: 20px;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
.public-banner-simple{
  background: #0CA18F url("../cn/cnImg/PublicBannerBackground.webp") no-repeat center center;
  background-size: cover;
  height: 200px;
  font-size: 44px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  h1{
    font-size: 30px;
    color: #FFFFFF;
    padding: 5%;
    em{
      width: 100%;
      display: block;
      color: #fff;
      font-size: 16px;
      padding-top: 8px;
      font-weight: normal;
    }
  }
}
.noPage{
  text-align: center;
  width: 100%;
  height: 100vh;
  padding-top:10%;
  img{
    width: fit-content;
    height: fit-content;
  }
  .textBox404{
    text-align: center;
    width: 100%;
    color: #888;
    font-size: 16px;
    margin:30px 0;
    line-height: 2;
  }
  .btnBox404{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    a{
      margin:0 20px;
      background: #07A696;
      color: #fff;
      border-radius: 5px;
      padding:10px 20px;
      &:hover{
        background: #03c2af;
      }
    }
  }
}

@media screen and (max-width: 830px) {
  .public-banner-simple {
    background-size: cover;
    height: 100px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    h1{
      font-size: 20px;
      em{
        font-size: 14px;
      }
    }
  }
  .padNone{
    padding-left:0 !important;
    padding-right:0 !important;
  }
  .boxShadowNone{
    box-shadow: none !important;
    background: transparent !important;
    .titleTable .titleTableItem{
      padding:0 6px;
    }
  }
}

@media screen and (max-width: 400px) {
  .public-banner-simple {
    h1{
      font-size: 14px;
      em{
        font-size: 12px;
      }
    }
  }
  .app_mailBanner .app_bannerBox .topBox h1{
    font-size: 22px;
  }
}
@media screen and (max-width: 375px) {
  .public-banner-simple {
    h1{
      em{
        font-size: 12px;
      }
    }
  }
  .toTopBox{
    .toTopList{
      .toTopCon{
        right:-15px;
        width: 100vw;
      }
    }
  }
}

