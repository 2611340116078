.index_main {
  display: block;
}
* {
  -webkit-touch-callout: none;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Microsoft YaHei", PingFangSC-Regular, PingFang SC, sans-serif;
}
a {
  text-decoration: none;
  font-size: 14px;
  color: #333;
}
a:hover {
  text-decoration: none;
  color: #009688;
}
a:focus {
  outline: none;
  color: #333;
}
a:hover {
  text-decoration: none;
}
body,
html,
.app {
  padding: 0;
  margin: 0;
  background: #fff;
}
img {
  max-width: 100%;
}
a,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
span,
p,
em,
img,
input,
button,
div {
  font-size: 14px;
  color: #333;
  font-style: normal;
  list-style: none;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  margin: 0;
  padding: 0;
}
a:focus,
body:focus,
h1:focus,
h2:focus,
h3:focus,
h4:focus,
h5:focus,
h6:focus,
ul:focus,
li:focus,
span:focus,
p:focus,
em:focus,
img:focus,
input:focus,
button:focus,
div:focus {
  outline: none;
}
.noPad {
  padding: 0 !important;
}
p {
  line-height: 1.5;
}
div {
  font-size: 0;
}
/* Safari，Chrome WebKit browsers */
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #999;
}
/* 火狐 Mozilla Firefox 4 to 18 */
textarea::-webkit-input-placeholder,
input:-moz-placeholder {
  color: #999;
}
/* 火狐 Mozilla Firefox 19+ */
textarea::-webkit-input-placeholder,
input::-moz-placeholder {
  color: #999;
}
/* Internet Explorer 10+ */
textarea::-webkit-input-placeholder,
input:-ms-input-placeholder {
  color: #999;
}
.color-pink {
  color: #eb2f96;
}
.color-red {
  color: #f5222d;
}
.color-orange {
  color: #fa8c16;
}
.color-green {
  color: #52c41a;
}
.color-cyan {
  color: #13c2c2 !important;
}
.color-blue {
  color: #1890ff !important;
}
.color-purple {
  color: #722ed1 !important;
}
select,
textarea,
input {
  outline: none;
}
.pc_headerMain {
  width: 100%;
  background: #E6F7F5;
  height: 70px;
  padding: 0 15px;
}
.pc_headerMain .pc_headerBox {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
}
.pc_headerMain .pc_headerBox .leftDiv {
  max-width: 181px;
}
.pc_headerMain .pc_headerBox .rightDiv {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.pc_headerMain .pc_headerBox .rightDiv .search {
  width: 20%;
  height: 32px;
  background: #009688;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.pc_headerMain .pc_headerBox .rightDiv .search input {
  background: transparent;
  width: 100% !important;
  padding: 0 32px 0 8px;
  border-color: transparent;
  height: 100%;
  color: #fff;
}
.pc_headerMain .pc_headerBox .rightDiv .search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.pc_headerMain .pc_headerBox .rightDiv .search img {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}
.pc_headerMain .pc_headerBox .rightDiv .search .history-data {
  display: none;
  position: absolute;
  top: 32px;
  width: 100%;
  z-index: 111;
  background: #fafafa;
  border-top: 1px solid #f2f2f2;
  line-height: normal;
  text-align: left;
  padding: 10px ;
}
.pc_headerMain .pc_headerBox .rightDiv .search .history-data .search-history-wrapper {
  font-size: 0.75rem;
  margin-top: 10px;
  box-sizing: border-box;
  height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}
.pc_headerMain .pc_headerBox .rightDiv .searchActive {
  width: 30%;
}
.pc_headerMain .pc_headerBox .rightDiv .rItem {
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 30px;
  overflow: hidden;
}
.pc_headerMain .pc_headerBox .rightDiv .rItem img {
  width: 30px;
}
.pc_headerMain .pc_headerBox .rightDiv .rItem a {
  margin-left: 10px;
  opacity: 0;
  white-space: nowrap;
}
.pc_headerMain .pc_headerBox .rightDiv .rItem:hover a {
  opacity: 1;
  color: #07A696;
}
.pc_headerMain .pc_headerBox .rightDiv .yuyan {
  margin-left: 20px;
  width: 100px;
  height: 32px;
  background: #009688;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pc_headerMain .pc_headerBox .rightDiv .yuyan:hover {
  background: #07A696;
}
.pc_headerMain .pc_headerBox .rightDiv .yuyan img {
  width: 16px;
  margin-right: 4px;
}
.pc_headerMain .pc_headerBox .rightDiv .yuyan p {
  color: #fff;
  font-size: 14px;
}
.aOne:hover {
  text-decoration: underline;
}
.pc_menuMain {
  background: #02796B;
  padding: 0 15px;
}
.pc_menuMain .pc_menuBox {
  max-width: 1200px;
  margin: 0 auto;
  height: 48px;
}
.pc_menuMain .pc_menuBox ul {
  height: 48px;
  display: flex;
  justify-content: space-between;
}
.pc_menuMain .pc_menuBox ul li {
  position: relative;
  width: 14.28%;
}
.pc_menuMain .pc_menuBox ul li:after {
  height: 30px;
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  position: absolute;
  top: 9px;
}
.pc_menuMain .pc_menuBox ul li:last-child:before {
  height: 30px;
  width: 1px;
  background: rgba(255, 255, 255, 0.1);
  content: "";
  position: absolute;
  top: 9px;
  right: 0;
}
.pc_menuMain .pc_menuBox ul li a {
  color: #fff;
  height: 48px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pc_menuMain .pc_menuBox ul li a:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.3);
}
.pc_menuMain .pc_menuBox ul li a img {
  margin-left: 8px;
}
.pc_menuMain .pc_menuBox ul li .showDiv {
  position: absolute;
  top: 48px;
  background: #026357;
  width: 410px;
  display: none;
  z-index: 10;
}
.pc_menuMain .pc_menuBox ul li .showDiv a {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  height: auto;
  padding: 10px;
}
.pc_menuMain .pc_menuBox ul li .showDiv a i {
  font-size: 40px;
  margin-right: 8px;
}
.pc_menuMain .pc_menuBox ul li .showDiv a h2 {
  display: flex;
  flex-wrap: wrap;
  font-weight: normal;
}
.pc_menuMain .pc_menuBox ul li .showDiv a h2 span {
  width: 100%;
  color: #FFFFFF;
  display: block;
  text-align: left;
  font-size: 16px;
  margin-bottom: 4px;
  line-height: 1.8;
  font-weight: normal;
}
.pc_menuMain .pc_menuBox ul li .showDiv a h2 span:last-child {
  font-size: 12px;
  font-weight: normal;
  line-height: 1.5;
}
.pc_menuMain .pc_menuBox ul li .showDiv a:hover {
  color: #fff;
  text-align: left;
  background: none;
  background: #009582;
}
.pc_menuMain .pc_menuBox ul li .showDiv a:hover h2 span {
  color: #fff;
}
.pc_menuMain .pc_menuBox ul li .jp_showDiv {
  width: 170%;
}
.pc_menuMain .pc_menuBox ul li:hover a img {
  transform: rotate(90deg);
}
.pc_menuMain .pc_menuBox ul li:hover .showDiv {
  display: block;
}
.app_headerMain,
.app_menuMain {
  display: none;
}
.pc_headerMain_ac {
  position: fixed;
  top: 0;
  z-index: 19;
  width: 100%;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2) !important;
}
.app_headerMain_ac {
  position: fixed;
  top: 0;
  z-index: 19;
  width: 100%;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2) !important;
}
.pc_menuMain_ac {
  position: fixed;
  width: 100%;
  top: 70px;
  z-index: 19;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2) !important;
}
.pc_footerMain {
  background: #30343E;
  padding: 0 15px;
}
.pc_footerMain .pc_footerBox {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}
.pc_footerMain .pc_footerBox ul {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 2px solid #434753;
}
.pc_footerMain .pc_footerBox ul li {
  width: 25%;
  padding-left: 3%;
}
.pc_footerMain .pc_footerBox ul li a {
  font-size: 18px;
  color: #fff;
  margin-bottom: 15px;
  display: block;
}
.pc_footerMain .pc_footerBox ul li a:hover {
  color: #fff;
}
.pc_footerMain .pc_footerBox ul li .bomList a {
  color: #A2A6B2;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  display: block;
  margin: 0;
}
.pc_footerMain .pc_footerBox ul li .bomList a:hover {
  color: #009688;
}
.pc_footerMain .pc_footerBox ul li .bomList a em {
  display: none;
}
.pc_footerMain .pc_footerBox .footerMenuList {
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 2px solid #434753;
}
.pc_footerMain .pc_footerBox .footerMenuList a {
  font-size: 18px;
  color: #fff;
  width: 25%;
  padding-left: 3%;
}
.pc_footerMain .pc_footerBox .footerMenuList a:hover {
  color: #00A693;
}
.pc_footerMain .pc_footerBox .footerOuth {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  margin-top: 10px;
}
.pc_footerMain .pc_footerBox .footerOuth a {
  color: #A2A6B2;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.pc_footerMain .pc_footerBox .footerOuth a img {
  margin-right: 5px;
}
.pc_footerMain .pc_footerBox .footerOuth a:hover {
  color: #fff;
}
.pc_footerMain .pc_footerBox .footerOuth p {
  color: #A2A6B2;
  margin: 0 ;
}
.pc_footerMain .pc_footerBox .footerImgList {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  margin-top: 10px;
}
.pc_footerMain .pc_footerBox .footerImgList a {
  margin: 0 18px;
}
.pc_footerMain .pc_footerBox p.footerText {
  color: #A2A6B2;
  text-align: center;
}
.tabTitleMain_ac,
.tabTitleMain_ac2 {
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
  position: fixed !important;
  background: #009688 !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 99999 !important;
  margin: 0 !important;
  height: 48px !important;
  padding: 0 10px !important;
  box-shadow: 0 5px 7px rgba(0, 0, 0, 0.2) !important;
  max-width: 100% !important;
}
.tabTitleMain_ac .tabTitleBox,
.tabTitleMain_ac2 .tabTitleBox {
  position: initial !important;
  top: 10px !important;
  display: flex !important;
  max-width: 1200px !important;
  margin: 0 auto !important;
  justify-content: space-between !important;
  padding: 0 !important;
  height: 48px !important;
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
}
.tabTitleMain_ac .tabTitleBox a:after,
.tabTitleMain_ac2 .tabTitleBox a:after {
  top: 0 !important;
}
.tabTitleMain_ac2 {
  padding: 0  !important;
}
.tabTitleMain_ac2 .tabTitleBox {
  width: 100% !important;
}
.navTitle {
  width: 100%;
  background: #0CA18F;
}
.navTitle .navTitleBox {
  line-height: 1.5;
  font-size: 12px;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;
  padding: 8px 0;
}
.navTitle .navTitleBox a {
  color: #fff;
  font-size: 12px;
  display: inline-block;
  margin: 0 10px ;
}
.navTitle .navTitleBox a:hover {
  text-decoration: underline;
}
.navTitle .navTitleBox a:first-child {
  margin-left: 0;
}
.bottomBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  background: #FFFFFF;
  box-shadow: 0px -2px 20px 0px rgba(0, 0, 0, 0.05);
}
.bottomBtn a {
  background: #fff;
  box-shadow: 0 -2px 20px 0px rgba(0, 0, 0, 0.05);
  color: #009688;
  width: 140px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  border: 1px solid #009688;
  border-radius: 2px;
  font-size: 18px;
}
.bottomBtn a.active {
  background: #009688;
  box-shadow: 0 -2px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  color: #fff;
}
.bottomBtn a.active:hover {
  background: rgba(0, 150, 136, 0.8);
}
.bottomBtn a:hover {
  background: rgba(0, 150, 136, 0.1);
}
.mailBanner {
  height: 400px;
  width: 100%;
  background: linear-gradient(87deg, #0FC9B7 0%, #009688 100%);
}
.mailBanner .bannerBox {
  width: 100%;
  position: relative;
  height: 400px;
  background: url("../cn/cnImg/z_mailBannerBg.png") no-repeat center center;
}
.mailBanner .bannerBox .bannerMain {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
}
.mailBanner .bannerBox .bannerMain .text {
  position: absolute;
  top: 80px;
  z-index: 9;
  left: 0;
}
.mailBanner .bannerBox .bannerMain .text h1 {
  color: #fff;
  font-size: 44px;
  margin-bottom: 10px;
  font-weight: normal;
  text-align: left;
}
.mailBanner .bannerBox .bannerMain .text ul li {
  list-style: disc;
  font-size: 20px;
  color: #fff;
  text-align: left;
  margin-left: 20px;
  line-height: 1.5;
}
.mailBanner .bannerBox .bannerMain .text_jp ul li {
  font-size: 16px;
}
.mailBanner .bannerBox .bannerMain .text_jp_xn {
  top: 60px;
}
.mailBanner .bannerBox .bannerMain .text_jp_xn ul li {
  font-size: 14px;
}
.mailBanner .bannerBox .bannerMain .domainText {
  top: 60px;
}
.mailBanner .bannerBox .bannerMain img.rightImg {
  position: absolute;
  right: 0;
  height: auto;
  z-index: 1;
  top: 0;
  max-height: 100%;
}
.mailBanner .bannerBox .bannerMain img.rightImg_jp {
  max-height: 80%;
  top: 0;
}
.mailBanner .bannerBox .bannerMain .dataSafetyTop {
  top: 40px;
}
.mailBanner .bannerBox .bannerMain .dataSafetyTop p {
  font-size: 20px;
  color: #fff;
  max-width: 55%;
  padding-right: 20px;
}
.mailBanner .bannerBox .bannerMain .dataSafetyTopJp {
  max-width: 50%;
  top: 60px;
}
.mailBanner .bannerBox .bannerMain .dataSafetyTopJp h1 {
  font-size: 34px;
}
.mailBanner .bannerBox .bannerMain .dataSafetyTopJp p {
  max-width: 100%;
}
.mailBanner .bannerBox .bannerMain .dataSafetyBanner {
  position: relative;
  width: 100%;
  height: 100%;
}
.mailBanner .bannerBox .bannerMain .dataSafetyBanner .imgBox {
  width: 40%;
  position: absolute;
  right: 5%;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  top: 50%;
  transform: translateY(-58%);
  z-index: 9;
}
.mailBanner .bannerBox .bannerMain .dataSafetyBanner .imgBox img {
  width: 100%;
  cursor: pointer;
}
.app_mailBanner {
  display: none;
}
.toTopBox {
  position: fixed;
  right: 15px;
  top: 80%;
  margin-top: -50px;
  display: flex;
  flex-wrap: wrap;
  width: 42px;
  z-index: 10;
}
.toTopBox .toTopList {
  width: 100%;
  height: 42px;
  margin-bottom: 15px;
  font-size: inherit;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #009688;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
  border-radius: 42px;
  cursor: pointer;
}
.toTopBox .toTopList:hover {
  background: #17c9b7;
}
.toTopBox .toTopList:last-child {
  background: #fff;
}
.toTopBox .toTopList:last-child:hover {
  background: #ebfff9;
}
.toTopBox .toTopList img {
  cursor: pointer;
}
.toTopBox .toTopList a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.toTopBox .toTopList .toTopCon {
  display: none;
  width: 255px;
  padding: 15px;
  background: #fff;
  position: absolute;
  right: 65px;
  box-shadow: 1px -1px 38px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 9;
  top: -38px;
}
.toTopBox .toTopList .toTopCon:after {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  /* Internet Explorer */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
  /* Opera */
  content: "";
  right: -10px;
  top: 50%;
  margin-top: -10px;
  z-index: 0;
}
.toTopBox .toTopList .toTopCon span {
  font-size: 16px;
  color: #666;
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.toTopBox .toTopList .toTopCon p {
  font-size: 14px;
  color: #666;
}
.toTopBox .toTopList .toTopCon img {
  width: 30px;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.6;
}
.blockBg {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999991 !important;
  top: 0;
  left: 0;
  display: none;
}
.popMain,
.domainPop {
  display: none;
  position: fixed;
  top: 10vh;
  width: 880px;
  left: 50%;
  margin-left: -440px;
  max-height: 80vh;
  background: #fff;
  z-index: 999992;
  overflow: hidden;
  border-radius: 2px;
}
.popMain .popMain_T,
.domainPop .popMain_T {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  position: relative;
}
.popMain .popMain_T .close,
.domainPop .popMain_T .close {
  position: absolute;
  right: 20px;
  top: 15px;
  width: 27px;
  height: 27px;
  cursor: pointer;
  z-index: 11;
}
.popMain .popMain_T .close img,
.domainPop .popMain_T .close img {
  max-width: 100%;
}
.popMain .popMain_T h1,
.domainPop .popMain_T h1 {
  text-align: center;
  font-size: 20px;
  position: relative;
  display: initial;
  margin-bottom: 5px;
  width: 100%;
}
.popMain .popMain_T p,
.domainPop .popMain_T p {
  font-size: 14px;
  width: 100%;
}
.popMain .popMain_T p em,
.domainPop .popMain_T p em {
  color: #ff0000;
}
.popMain .popCon,
.domainPop .popCon {
  margin: 0 auto;
  max-height: calc(80vh - 92px);
  overflow-y: auto;
  width: 100%;
  padding: 0 4%;
  border-bottom: 1px solid #e8e8e8;
}
.popMain .popCon p,
.domainPop .popCon p {
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.popMain .popCon .tabBox,
.domainPop .popCon .tabBox {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 24px;
  padding-bottom: 5px;
}
.popMain .popCon .tabBox ul li,
.domainPop .popCon .tabBox ul li {
  display: flex;
  padding: 0 0 15px 0;
  font-size: 16px;
  align-items: center;
}
.popMain .popCon .tabBox ul li em,
.domainPop .popCon .tabBox ul li em {
  color: #FF0000;
  font-size: 14px;
  font-weight: bold;
  width: 20px;
  text-align: center;
}
.popMain .popCon .tabBox ul li .iptBox,
.domainPop .popCon .tabBox ul li .iptBox,
.popMain .popCon .tabBox ul li .domainIpnt,
.domainPop .popCon .tabBox ul li .domainIpnt {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popMain .popCon .tabBox ul li .iptBox select,
.domainPop .popCon .tabBox ul li .iptBox select,
.popMain .popCon .tabBox ul li .domainIpnt select,
.domainPop .popCon .tabBox ul li .domainIpnt select {
  border-radius: 4px;
  width: 49%;
  min-width: auto;
  margin-right: 10px;
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  font-size: 14px;
}
.popMain .popCon .tabBox ul li .iptBox select:last-child,
.domainPop .popCon .tabBox ul li .iptBox select:last-child,
.popMain .popCon .tabBox ul li .domainIpnt select:last-child,
.domainPop .popCon .tabBox ul li .domainIpnt select:last-child {
  margin: 0;
}
.popMain .popCon .tabBox ul li .iptBox textarea,
.domainPop .popCon .tabBox ul li .iptBox textarea,
.popMain .popCon .tabBox ul li .domainIpnt textarea,
.domainPop .popCon .tabBox ul li .domainIpnt textarea,
.popMain .popCon .tabBox ul li .iptBox input,
.domainPop .popCon .tabBox ul li .iptBox input,
.popMain .popCon .tabBox ul li .domainIpnt input,
.domainPop .popCon .tabBox ul li .domainIpnt input {
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}
.popMain .popCon .tabBox ul li .domainIpnt input,
.domainPop .popCon .tabBox ul li .domainIpnt input {
  border-radius: 4px 0 0 4px;
  border-right: none ;
  height: 31px;
}
.popMain .popCon .tabBox ul li .domainIpnt select,
.domainPop .popCon .tabBox ul li .domainIpnt select {
  border-radius: 0 4px 4px 0;
  height: 31px;
  width: 150px;
}
.popMain .popCon .tabBox ul li span,
.domainPop .popCon .tabBox ul li span {
  border: 1px solid #ccc;
  color: #333;
  height: 20px;
  width: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.popMain .popCon .tabBox button,
.domainPop .popCon .tabBox button {
  border-radius: 4px;
  border: 1px solid #009688;
  background: #fff;
  color: #009688;
  font-size: 14px;
  padding: 5px 10px;
  margin: 10px auto;
  display: block;
  cursor: pointer;
}
.popMain .popCon .tabBox button:hover,
.domainPop .popCon .tabBox button:hover {
  background: #e9fffb;
}
.popMain .popCon .radioBox,
.domainPop .popCon .radioBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 15px 5px 15px;
  margin-bottom: 24px;
}
.popMain .popCon .radioBox label,
.domainPop .popCon .radioBox label {
  width: 33.33%;
  color: #000;
  font-size: 14px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.popMain .popCon .radioBox label input[type="radio"],
.domainPop .popCon .radioBox label input[type="radio"] {
  width: 16px;
  height: 16px;
  border: 1px solid #e8e8e8;
  margin-right: 5px;
  position: relative;
}
.popMain .popCon .radioBox label input[type=radio]::after,
.domainPop .popCon .radioBox label input[type=radio]::after {
  position: absolute;
  top: 3px;
  left: 3px;
  background-color: #fff;
  color: #fff;
  width: 10px;
  height: 10px;
  display: inline-block;
  visibility: visible;
  padding-left: 0;
  text-align: center;
  content: ' ';
  box-sizing: border-box;
  border-radius: 10px;
}
.popMain .popCon .radioBox label input[type=radio]:checked::before,
.domainPop .popCon .radioBox label input[type=radio]:checked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 14px;
  height: 14px;
  border: 1px solid #009a74;
  border-radius: 16px;
  z-index: 1;
}
.popMain .popCon .radioBox label input[type=radio]:checked::after,
.domainPop .popCon .radioBox label input[type=radio]:checked::after {
  content: "";
  background-color: #009a74;
  border-color: #009a74;
}
.popMain .popCon .isYmName,
.domainPop .popCon .isYmName {
  display: none;
}
.popMain .popCon .ymRadioBox,
.domainPop .popCon .ymRadioBox {
  justify-content: center;
  margin: 0;
}
.popMain .popCon .ymRadioBox label,
.domainPop .popCon .ymRadioBox label {
  width: 15%;
  justify-content: center;
}
.popMain .popCon .yzmBox,
.domainPop .popCon .yzmBox {
  display: flex;
  padding: 0 ;
  font-size: 1rem;
  align-items: center;
  justify-content: space-between;
}
.popMain .popCon .yzmBox input,
.domainPop .popCon .yzmBox input {
  padding: 5px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  font-size: 14px;
  flex: 1;
}
.popMain .popCon .yzmBox em,
.domainPop .popCon .yzmBox em {
  color: #FF0000;
  font-size: 14px;
  font-weight: bold;
  width: 20px;
  text-align: center;
}
.popMain .popCon .yzmBox span,
.domainPop .popCon .yzmBox span {
  width: 100px;
  height: 31px;
  border: 1px solid #e8e8e8;
}
.popMain .popCon .yzmBox span img,
.domainPop .popCon .yzmBox span img {
  width: 98px;
  height: 29px;
}
.popMain .popCon .textB,
.domainPop .popCon .textB {
  width: 100%;
}
.popMain .popCon .textB label,
.domainPop .popCon .textB label {
  display: flex;
  margin: 25px auto 0 auto;
  align-items: center;
}
.popMain .popCon .textB label input[type="checkbox"],
.domainPop .popCon .textB label input[type="checkbox"] {
  width: 15px;
  height: 15px;
  border: 1px solid #d1d1d1;
  margin-right: 5px;
  position: relative;
}
.popMain .popCon .textB label input[type=checkbox]::after,
.domainPop .popCon .textB label input[type=checkbox]::after {
  position: absolute;
  top: 0;
  background-color: #fff;
  color: #fff;
  width: 14px;
  height: 14px;
  display: inline-block;
  visibility: visible;
  padding-left: 0;
  text-align: center;
  content: ' ';
  border-radius: 2px;
  box-sizing: border-box;
  border: 1px solid #ddd;
}
.popMain .popCon .textB label input[type=checkbox]:checked::after,
.domainPop .popCon .textB label input[type=checkbox]:checked::after {
  content: "";
  background-color: #009a74;
  border-color: #009a74;
}
.popMain .popCon .textB label input[type=checkbox]:checked::before,
.domainPop .popCon .textB label input[type=checkbox]:checked::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 5px;
  width: 3px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 1;
}
.popMain .popCon .textB label p,
.domainPop .popCon .textB label p {
  flex: 1;
  font-weight: normal;
  line-height: 1.5;
  font-size: 12px;
  text-align: left;
}
.popMain .popCon .textB label p a,
.domainPop .popCon .textB label p a {
  color: #009688;
}
.popMain .popCon .bomBtn,
.domainPop .popCon .bomBtn {
  padding: 20px 0 ;
  width: 100%;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  display: flex;
  border-top: 1px solid #e8e8e8;
}
.popMain .popCon .bomBtn button,
.domainPop .popCon .bomBtn button {
  width: 138px;
  height: 42px;
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}
.popMain .popCon .bomBtn button:hover,
.domainPop .popCon .bomBtn button:hover {
  background: #008363;
  color: #fff;
}
.popMain .popCon .bomBtn button:last-child,
.domainPop .popCon .bomBtn button:last-child {
  background: #008363;
  color: #fff;
  border: 1px solid #008363;
}
.popMain .popCon .bomBtn button:last-child:hover,
.domainPop .popCon .bomBtn button:last-child:hover {
  background: #019b76;
}
.popMain .popCon .bomBtn button:disabled,
.domainPop .popCon .bomBtn button:disabled {
  background: #555;
  color: #fff;
  border: 1px solid #555;
  cursor: no-drop;
}
.popMain .popCon .bomBtn button:disabled:hover,
.domainPop .popCon .bomBtn button:disabled:hover {
  background: #555;
  color: #fff;
  border: 1px solid #555;
  cursor: no-drop;
}
.hintWan,
.hintSuc {
  width: 300px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  position: fixed;
  top: 0;
  left: 50%;
  margin-left: -150px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-around;
  flex-wrap: wrap;
  z-index: -1;
  opacity: 0;
}
.hintWan p,
.hintSuc p {
  color: #333;
  font-size: 14px;
  text-align: center;
  flex: 1;
}
.hintWan img,
.hintSuc img {
  margin-right: 5px;
}
.hintWan_ac,
.hintSuc_ac {
  top: 5%;
  z-index: 999993;
  opacity: 1;
}
.clearBf:after {
  display: none !important;
}
.clearBf:before {
  display: none !important;
}
.domainSearchPop {
  width: 350px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: fixed;
  top: 20%;
  left: 50%;
  margin-left: -175px;
  z-index: 9999994;
  padding: 15px 15px 55px 15px;
  display: none;
}
.domainSearchPop button {
  background: #009688;
  color: #fff;
  border: none;
  padding: 5px 8px;
  border-radius: 2px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
.domainSearchPop ul li {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
}
.domainSearchPop ul li img {
  margin-right: 10px;
  width: 18px;
}
.domainSearchPop ul li:first-child {
  margin: 0;
}
/* ----- footer-policy ----- */
.footer-policy {
  padding: 20px ;
  background-color: #eee;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.1);
}
.footer-policy .policy_box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  background-color: #EEEEEE;
  padding: 20px;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.footer-policy .policy_text {
  margin-right: 1em;
  color: #000;
  width: 100%;
}
.footer-policy .policy_action {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-align-items: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 15px;
}
.footer-policy .policy_action > [class*="policy_"] {
  display: block;
  min-width: 115px;
  margin-right: 10px;
  padding: 5px;
  border: none;
  color: #fff;
  line-height: 1.5;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  width: 65px;
  background: #02796B;
  border-radius: 4px;
}
.footer-policy .policy_action > [class*="policy_"]:hover {
  background: #009e8d;
}
.footer-policy .policy_action button span,
.footer-policy .policy_action a span {
  color: #fff;
  font-size: 14px;
}
.footer-policy .policy_action a.policy_detail {
  background: #fff;
  border: 1px solid #02796B;
}
.footer-policy .policy_action a.policy_detail span {
  color: #02796B;
}
.footer-policy .policy_action a.policy_detail:hover {
  background: #e1fff8;
}
@media screen and (max-width: 1200px) {
  .mailBanner .bannerBox .bannerMain .text {
    left: 15px;
  }
  .mailBanner .bannerBox .bannerMain img.rightImg {
    right: 20px;
  }
}
@media screen and (max-width: 880px) {
  .popMain,
  .domainPop {
    width: 96%;
    margin: 0;
    left: 2%;
  }
  .popMain .popCon .radioBox,
  .domainPop .popCon .radioBox {
    padding: 10px 0 5px 0;
  }
  .popMain .popCon .radioBox label,
  .domainPop .popCon .radioBox label {
    width: 50%;
  }
  .popMain .popCon .tabBox ul li,
  .domainPop .popCon .tabBox ul li {
    padding: 0 0 15px 0;
  }
  .popMain .popCon .yzmBox,
  .domainPop .popCon .yzmBox {
    padding: 0 ;
  }
}
@media screen and (max-width: 830px) {
  .footer-policy .policy_box {
    padding: 0;
  }
  .popMain,
  .domainPop {
    top: 5vh;
    height: 90vh;
  }
  .popMain .popMain_T h1,
  .domainPop .popMain_T h1 {
    font-size: 16px;
  }
  .popMain .popCon,
  .domainPop .popCon {
    max-height: calc(90vh - 174px);
  }
  .popMain .popCon p,
  .domainPop .popCon p {
    font-size: 14px;
  }
  .popMain .popCon .tabBox ul li .selectBox,
  .domainPop .popCon .tabBox ul li .selectBox {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .popMain .popCon .tabBox ul li .selectBox .selectCon,
  .domainPop .popCon .tabBox ul li .selectBox .selectCon {
    width: 100%;
    display: flex;
    margin: 0 0 15px 0;
  }
  .popMain .popCon .tabBox ul li .selectBox .selectCon:last-child,
  .domainPop .popCon .tabBox ul li .selectBox .selectCon:last-child {
    margin: 0;
  }
  .popMain .popCon .tabBox ul li .selectBox .selectCon select,
  .domainPop .popCon .tabBox ul li .selectBox .selectCon select {
    width: 100%;
    margin: 0;
  }
  .popMain .popCon .tabBox ul li .rf input,
  .domainPop .popCon .tabBox ul li .rf input {
    width: 100%;
  }
  .popMain .bomBtn button,
  .domainPop .bomBtn button {
    font-size: 16px;
  }
  .navTitle .navTitleBox {
    padding-left: 5px;
  }
  .pc_headerMain,
  .pc_menuMain {
    display: none;
  }
  .app_headerMain {
    height: 62px;
    background: #02796B;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .app_headerMain .leftLogo img {
    max-width: 150px;
  }
  .app_headerMain .rightIcon {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .app_headerMain .rightIcon svg,
  .app_headerMain .rightIcon img {
    margin-left: 24px;
    width: 22px;
  }
  .app_menuMain {
    background: #02796B;
    position: fixed;
    width: 100%;
    display: none;
    z-index: 11;
  }
  .app_menuMain .menuC1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.49);
    flex-wrap: wrap;
  }
  .app_menuMain .menuC1 .menuC1Item {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.49);
    height: 44px;
    padding: 0 15px;
  }
  .app_menuMain .menuC1 .menuC1Item img {
    width: 30px;
    margin-right: 8px;
  }
  .app_menuMain .menuC1 .menuC1Item a {
    color: #fff;
    flex: 1;
  }
  .app_menuMain .menuC1 .menuC1Item a:hover {
    color: #fff;
    font-size: 14px;
  }
  .app_menuMain .menuC2 {
    padding: 12px 0;
    height: 64px;
    display: flex;
    justify-content: center;
  }
  .app_menuMain .menuC2 a {
    width: 94px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background: #fff;
    color: #333;
    border-radius: 2px;
    margin: 0 20px;
  }
  .app_menuMain .menuC2 a.active {
    background: #00A693;
    color: #fff;
  }
  .app_menuMain ul li {
    width: 100%;
  }
  .app_menuMain ul li a {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 0 15px;
    color: #fff;
    justify-content: space-between;
    border-top: 1px solid rgba(255, 255, 255, 0.49);
  }
  .app_menuMain ul li a.toSvg svg:first-child {
    display: none;
  }
  .app_menuMain ul li .showDiv {
    width: 100%;
    display: none;
  }
  .app_menuMain ul li .showDiv a {
    width: 100%;
    height: 44px;
    display: flex;
    background: #00A693;
    align-items: center;
    font-size: 12px;
    padding: 0 15px;
  }
  .pc_footerMain .pc_footerBox {
    padding: 10px 0;
  }
  .pc_footerMain .pc_footerBox ul {
    flex-wrap: wrap;
  }
  .pc_footerMain .pc_footerBox ul li {
    width: 100%;
  }
  .pc_footerMain .pc_footerBox ul li a {
    margin: 20px 0 10px 0;
  }
  .pc_footerMain .pc_footerBox ul li .bomList {
    display: flex;
    flex-wrap: wrap;
  }
  .pc_footerMain .pc_footerBox ul li .bomList a {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .pc_footerMain .pc_footerBox ul li .bomList a em {
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background: #A2A6B2;
    margin-right: 4px;
  }
  .pc_footerMain .pc_footerBox ul li .jp_bomList a {
    height: auto;
    line-height: normal;
    align-items: inherit;
    padding-right: 10px;
    margin-bottom: 10px;
  }
  .pc_footerMain .pc_footerBox ul li .jp_bomList a:nth-child(2),
  .pc_footerMain .pc_footerBox ul li .jp_bomList a:nth-child(4) {
    padding: 0;
  }
  .pc_footerMain .pc_footerBox ul li .jp_bomList a em {
    margin-top: 9px;
  }
  .pc_footerMain .pc_footerBox .footerOuth {
    flex-wrap: wrap;
  }
  .pc_footerMain .pc_footerBox .footerOuth a,
  .pc_footerMain .pc_footerBox .footerOuth p {
    font-size: 12px;
    line-height: 1.5;
  }
  .pc_footerMain .pc_footerBox p.footerText {
    font-size: 12px;
  }
  .pc_footerMain .pc_footerBox .footerMenuList {
    flex-wrap: wrap;
    padding: 20px 0 0 0;
  }
  .pc_footerMain .pc_footerBox .footerMenuList a {
    width: 50%;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .mailBanner {
    display: none;
  }
  .app_mailBanner {
    display: block;
    width: 100%;
    padding: 0 15px;
    background: linear-gradient(87deg, #0FC9B7 0%, #009688 100%);
  }
  .app_mailBanner .app_bannerBox {
    background: url("../cn/cnImg/z_mailBannerBg.png") no-repeat center center;
    width: 100%;
  }
  .app_mailBanner .app_bannerBox .topBox {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
  }
  .app_mailBanner .app_bannerBox .topBox h1 {
    color: #fff;
    font-size: 24px;
  }
  .app_mailBanner .app_bannerBox .topBox .newRy {
    font-size: 18px;
  }
  .app_mailBanner .app_bannerBox .topBox img {
    height: 80%;
  }
  .app_mailBanner .app_bannerBox .topBox .dataSafetyBanner {
    position: relative;
    width: 100%;
  }
  .app_mailBanner .app_bannerBox .topBox .dataSafetyBanner .imgBox {
    width: 80%;
    position: absolute;
    right: 0;
    background: #fff;
    padding: 5px;
    border-radius: 5px;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
  }
  .app_mailBanner .app_bannerBox .topBox .dataSafetyBanner .imgBox img {
    width: 100%;
  }
  .app_mailBanner .app_bannerBox .bomBox {
    border-top: 1px solid rgba(255, 255, 255, 0.48);
    padding: 10px 0 50px 0;
    line-height: 2;
    color: #fff;
  }
  .app_mailBanner .app_bannerBox .bomBox ul li {
    list-style: disc;
    font-size: 12px;
    color: #fff;
    text-align: left;
    margin-left: 20px;
    line-height: 1.5;
  }
}
.public-banner-simple {
  background: #0CA18F url("../cn/cnImg/PublicBannerBackground.webp") no-repeat center center;
  background-size: cover;
  height: 200px;
  font-size: 44px;
  font-weight: bold;
  color: #FFFFFF;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.public-banner-simple h1 {
  font-size: 30px;
  color: #FFFFFF;
  padding: 5%;
}
.public-banner-simple h1 em {
  width: 100%;
  display: block;
  color: #fff;
  font-size: 16px;
  padding-top: 8px;
  font-weight: normal;
}
.noPage {
  text-align: center;
  width: 100%;
  height: 100vh;
  padding-top: 10%;
}
.noPage img {
  width: fit-content;
  height: fit-content;
}
.noPage .textBox404 {
  text-align: center;
  width: 100%;
  color: #888;
  font-size: 16px;
  margin: 30px 0;
  line-height: 2;
}
.noPage .btnBox404 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.noPage .btnBox404 a {
  margin: 0 20px;
  background: #07A696;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
}
.noPage .btnBox404 a:hover {
  background: #03c2af;
}
@media screen and (max-width: 830px) {
  .public-banner-simple {
    background-size: cover;
    height: 100px;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  .public-banner-simple h1 {
    font-size: 20px;
  }
  .public-banner-simple h1 em {
    font-size: 14px;
  }
  .padNone {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .boxShadowNone {
    box-shadow: none !important;
    background: transparent !important;
  }
  .boxShadowNone .titleTable .titleTableItem {
    padding: 0 6px;
  }
}
@media screen and (max-width: 400px) {
  .public-banner-simple h1 {
    font-size: 14px;
  }
  .public-banner-simple h1 em {
    font-size: 12px;
  }
  .app_mailBanner .app_bannerBox .topBox h1 {
    font-size: 22px;
  }
}
@media screen and (max-width: 375px) {
  .public-banner-simple h1 em {
    font-size: 12px;
  }
  .toTopBox .toTopList .toTopCon {
    right: -15px;
    width: 100vw;
  }
}
